<template>
  <div class="md-object-autocomplete">
    <b-autocomplete
      ref="autocomplete"
      v-model="q"
      :data="filteredData"
      :field="field"
      v-bind="$attrs"
      @select="(option) => (selected = option)"
    >
    </b-autocomplete>
    <slot :selected="selected" :value="value">
      <input v-if="name" type="hidden" :name="name" :value="value" />
    </slot>
  </div>
</template>

<script>
export default {
  props: {
    field: { type: String, default: 'name' },
    data: { type: Array, required: true },
    name: { type: String, default: null },
    initial: { type: Number, default: null },
  },
  data() {
    return {
      selected: null,
      q: '',
    }
  },
  computed: {
    filteredData() {
      return this.data.filter((option) => {
        return (
          option[this.field]
            .toString()
            .toLowerCase()
            .indexOf(this.q.toLowerCase()) >= 0
        )
      })
    },
    value() {
      return this.selected?.id
    },
  },
  mounted() {
    const initiallySelected = this.data.find((x) => x.id === this.initial)
    this.$refs.autocomplete.setSelected(initiallySelected)
  },
}
</script>

<style></style>
