<template>
  <div id="md-date-picker">
    <input v-model="model" :name="name" type="hidden" />
    <b-datepicker
      v-model="model"
      :editable="editable"
      v-bind="{ ...$attrs, ...$data }"
      icon="calendar-day"
      icon-prev="chevron-right"
      icon-next="chevron-left"
    ></b-datepicker>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    name: { type: String, default: null },
    value: { type: String, default: null },
    editable: { type: Boolean, default: true },
    // allow passing min and max as string 2020-12-31
    min: { type: String, default: null },
    max: { type: String, default: null },
    // handy props for past / future events
    isPast: { type: Boolean, default: false },
    isFuture: { type: Boolean, default: false },
  },
  data() {
    const today = new Date()
    return {
      model: this.value ? new Date(this.value) : undefined,
      minDate: this.min ? new Date(this.min) : this.isFuture ? today : undefined,
      maxDate: this.max ? new Date(this.max) : this.isPast ? today : undefined,
    }
  },
}
</script>
