import formatISO from 'date-fns/formatISO'
import isDate from 'lodash-es/isDate'

// JSON stringify automatically reverts dates to UTC
// which shifts the date part depending on the local timzone
// to prevent that, use custom replacer via _.cloneDeepWith
export function dateReplacer(v) {
  if (isDate(v)) return formatISO(v)
}

export function validationErrorToSnack(errors) {
  const lis = Object.values(errors)
    .flatMap((e) => `<li>${e}</li>`)
    .join('')
  const message = `<h6>لم تكتمل العملية بسبب الأخطاء التالية</h6><ul>${lis}</ul>`
  return {
    message,
    type: 'is-danger',
  }
}

export const successModalParams = {
  width: '10rem',
  content: `
    <p class="text-center text-6xl" style="width: fit-content; margin: auto;">
      <span class="icon has-text-success p-6 rounded-circle has-background-success-light">
        <i class="fas fa-check"></i>
      </span>
    </p>`,
}

export const successToastParams = {
  message: `<span class="icon p-4 text-2xl">
              <i class="fas fa-check" style="color: white"></i>
            </span>`,
  type: 'is-success',
}

export function wait(ms) {
  return new Promise((resolve) => setTimeout(() => resolve(), ms))
}

export default { dateReplacer, wait, successModalParams }
