<template lang="pug">
  b-upload.file-label(v-model="file" v-bind="$attrs")
    span.file-cta(:class="file ? 'rounded-right' : 'rounded'")
      b-icon.file-icon(icon="upload")
      span.file-label.mr-2 {{ label }}
    span.file-name.rounded-left(v-if="file") {{ file.name }}
</template>

<script>
export default {
  name: 'MdFileUpload',
  inheritAttrs: false,
  props: {
    label: { type: String, default: 'Click to upload' },
  },
  data: function() {
    return {
      file: null,
    }
  },
}
</script>
<style scoped>
.file-name {
  border-width: 1px;
  border-right-width: 0;
}
</style>
