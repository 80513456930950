<template lang="pug">
  div.family-member-education
    .columns.flex-row-reverse
      .column.is-narrow
        transition(name="fade")
          button.button.is-light.is-warning(@click="cancelEdit" v-if="isEditMode")
            span.icon.is-small: i.fas.fa-undo
            span {{ t('cancel') }}
          button.button.is-outlined.is-info(@click="isEditMode = true" v-if="!isEditMode")
            span.icon.is-small: i.fas.fa-edit
            span {{ t('edit') }}
      .column
        template(v-if="!isEditMode")
          b-field(grouped is-multiline)
            b-field(:label="t('family_member.almassar_number')")
              p.input.is-static.ltr.min-w-10 {{ value.almassar_number || '-' }}
            b-field(:label="t('family_member.apogee_number')")
              p.input.is-static.ltr.min-w-10 {{value.apogee_number || '-'}}

        form(v-if="isEditMode" @submit.prevent="onSubmit")
          b-field(grouped is-multiline)
            b-field(:label="t('family_member.almassar_number')")
              input.input.ltr(v-model="value.almassar_number" type="text" placeholder="ALMASSAR")
            b-field(:label="t('family_member.apogee_number')")
              input.input.ltr(v-model="value.apogee_number" type="text" placeholder="APOGEE")
            b-field(label="・")
              p.control
                input.button.is-primary(type="submit" :value="t('save')")
    .flex.flex-col.items-center.justify-start.mb-4(v-if="value.education_levels && value.education_levels.length === 0")
      p.tag.is-medium {{ t('education_level.empty_list') }}
    .columns(v-else)
      .column.is-narrow.years(style="min-width: 4em;")
        button.button(v-for="level in value.education_levels" :class="{ 'is-primary': selected === level }" @click="selected = level")
          | {{level.year}}
      .column.expanded
        transition(name="slide-fade")
          .card(v-if="selected" :key="selected.id"): .card-content
            family-member-education-level(:value="selected")
</template>
<script>
import axios from 'axios'
import cloneDeep from 'lodash-es/cloneDeep'
import FamilyMemberEducationLevel from './family-member-education-level.vue'
import UrlUtil from '../UrlUtil'

export default {
  components: {
    FamilyMemberEducationLevel,
  },
  props: {
    // value: { type: Array, required: true },
  },
  data() {
    return {
      isEditMode: false,
      value: {},
      initial_value: {},
      education_types: this.t('education_level.types'),
      selected: {},
    }
  },
  async mounted() {
    await this.fetchData()
  },
  methods: {
    async onSubmit() {
      let { almassar_number, apogee_number } = this.value
      if (!almassar_number && !apogee_number) return (this.isEditMode = false)
      const url = UrlUtil.current_family_member()
      const { data } = await axios.patch(url, {
        almassar_number,
        apogee_number,
      })
      this.initial_value.apogee_number = data.apogee_number
      this.initial_value.almassar_number = data.almassar_number
      this.value = cloneDeep(this.initial_value)
      this.$buefy.snackbar.open(this.t('update_success'))
      this.isEditMode = false
    },
    cancelEdit() {
      ;['almassar_number', 'apogee_number'].forEach((property) => {
        this.value[property] = this.initial_value[property]
      })
      this.isEditMode = false
    },
    async fetchData() {
      const url = UrlUtil.current_family_member_education_levels()
      const { data } = await axios.get(url)
      this.initial_value = data
      this.value = cloneDeep(data)
      this.selected = this.value.education_levels[0]
    },
  },
}
</script>
<style lang="scss" scoped>
.years {
  display: flex;
  flex-direction: column;
  gap: 2em;
}
</style>
