<template lang="pug">
  div#md-family-member
    // mixins
    mixin overlay_delete(member, index)
          .overlay-delete(v-show="value._destroy === true")
            p.title {{ t('to_be_detroyed') }}
            p.description {{ t('to_be_detroyed_desc') }}
            button.button.is-light.mt-4(@click="removeDestroyMark") {{ t('cancel') }}
    mixin support_cards
      section.mb-2.support-cards
        .columns
          .column.is-narrow
            h4: strong {{ t('family_member.support_cards.label') }}
          .column
            b-field(grouped group-multiline)
              .field(v-for="card in value.support_cards")
                b-checkbox(v-model="card.active")
                  | {{ t(`family_member.support_cards.types.${card.key}`) }}
        .field.is-grouped.is-grouped-multiline
          .field(v-if="card.active" v-for="card in value.support_cards")
            input.input(v-model="card.number" type="text" :placeholder="t('family_member.support_cards.card_number') + t(`family_member.support_cards.types.${card.key}`)")
    mixin disabilities_and_diseases
      fieldset.bordered.my-4
        legend {{ t('family_member.disabilities_and_diseases') }}          
        b-field(grouped group-multiline)
          b-field
            b-switch(v-model="value.has_disability" dir="ltr")
              | {{ t('family_member.has_disability') }}
          b-field
            b-switch(v-model="value.has_diseases" dir="ltr")
              | {{ t('family_member.has_diseases') }}
        b-field(grouped group-multiline v-if="value.has_disability")
          b-field(:label="t('family_member.disability_type')" expanded)
            input.input(v-model="value.disability_type" type="text")
          b-field(:label="t('family_member.disability_description')" expanded)
            input.input(v-model="value.disability_description" type="text")
        b-field(grouped group-multiline  v-if="value.has_diseases")
          b-field(:label="t('family_member.chronic_diseases')" expanded)
            b-taginput(
              v-model="value.chronic_diseases"
              :placeholder="t('press_enter_to_add')"
              ellipsis icon="label"
              :on-paste-separators="[',', '،']"
              )
          b-field(:label="t('family_member.repetitive_diseases')" expanded)
            input.input(v-model="value.repetitive_diseases" type="text")
    //- code: pre {{ JSON.stringify(value, null, 2) }}
    .box.box-member.box-father(v-if="value.relationship_to_orphans === 'father'")
      .flex.justify-end.mb-4
        span.tag.is-info.is-medium.tag-member
          | {{ t('family_member.relationships.father') }}
      .columns.is-desktop
        .column
          //- father
          b-field(grouped group-multiline)
            b-field(:label="t('family_member.first_name')" expanded)
              input.input(v-model="value.first_name" type="text")
            b-field(:label="t('family_member.first_name_fr')" expanded)
              input.input(v-model="value.first_name_fr" type="text" dir="ltr")
            b-field(:label="t('family_member.cin')" expanded)
              input.input(v-model="value.cin" type="text" dir="ltr")
          b-field(grouped group-multiline)
            b-field(:label="t('family_member.birth_date')" expanded)
              b-datepicker(
                v-model="value.birth_date"
                :placeholder="t('date.select_placeholder')"
                icon="calendar-day"
                icon-prev="chevron-right"
                icon-next="chevron-left"
                :editable="true"
              )
            b-field(:label="t('family_member.death_date')" expanded)
              b-datepicker(
                v-model="value.death_date"
                :placeholder="t('date.select_placeholder')"
                icon="calendar-day"
                icon-prev="chevron-right"
                icon-next="chevron-left"
                :editable="true"
              )
            b-field(:label="t('family_member.death_cause')" expanded)
              input.input(v-model="value.death_cause" type="text")

          b-field(grouped group-multiline)
            b-field(:label="t('family_member.father_job')" expanded)
              input.input(v-model="value.job" type="text")
            b-field(:label="t('family_member.employer')" expanded)
              input.input(v-model="value.employer" type="text")
            b-field(:label="t('family_member.notes')" expanded)
              input.input(v-model="value.notes" type="text")
          
          +support_cards
    .box.box-member.box-mother(v-if="value.relationship_to_orphans === 'mother'")
      .flex.justify-end.mb-4
        span.tag.is-info.is-medium.tag-member
          | {{ t('family_member.relationships.mother') }}
      .columns.is-desktop
        .column
          //- mother
          b-field(grouped group-multiline)
            b-field(:label="t('family_member.first_name')" expanded)
              input.input(v-model="value.first_name" type="text")
            b-field(:label="t('family_member.last_name')" expanded)
              input.input(v-model="value.last_name" type="text")
            b-field(:label="t('family_member.first_name_fr')" expanded)
              input.input(v-model="value.first_name_fr" type="text" dir="ltr")
            b-field(:label="t('family_member.last_name_fr')" expanded)
              input.input(v-model="value.last_name_fr" type="text" dir="ltr")
          
          b-field(:label="t('family_member.cin')" expanded)
            input.input(v-model="value.cin" type="text" dir="ltr")
          b-field(grouped group-multiline)
            b-field(:label="t('family_member.birth_date')" expanded)
              b-datepicker(
                v-model="value.birth_date"
                :placeholder="t('date.select_placeholder')"
                icon="calendar-day"
                icon-prev="chevron-right"
                icon-next="chevron-left"
                :editable="true"
              )
            b-field(:label="t('family_member.death_date')" expanded)
              b-datepicker(
                v-model="value.death_date"
                :placeholder="t('family_member.in_case_of_death')"
                icon="calendar-day"
                icon-prev="chevron-right"
                icon-next="chevron-left"
                :editable="true"
              )
            b-field(:label="t('family_member.death_cause')" expanded)
              input.input(v-model="value.death_cause" type="text" :placeholder="t('family_member.in_case_of_death')")

          b-field(grouped group-multiline)
            b-field(:label="t('family_member.mother_education_level')" expanded)
              input.input(v-model="value.mother_education_level" type="text")
            b-field(:label="t('family_member.mother_diplomas')" expanded)
              input.input(v-model="value.mother_diplomas" type="text")
            b-field(:label="t('family_member.mother_skills')" expanded)
              input.input(v-model="value.mother_skills" type="text")
          
          fieldset.bordered.my-4
            legend {{ t('family_member.work') }}
            .columns
              .column.is-narrow(style="margin-top: 32px")
                b-switch(
                  v-model="value.situation"
                  true-value="working" false-value=""
                  dir="ltr"
                )
              .column
                b-field(grouped group-multiline)
                  b-field(:label="t('family_member.job')" expanded)
                    input.input(v-model="value.job" type="text" :disabled="value.situation !== 'working'")
                  b-field(:label="t('family_member.job_level')" expanded)
                    input.input(v-model="value.job_level" type="text" :disabled="value.situation !== 'working'")
                  b-field(:label="t('family_member.employer')" expanded)
                    input.input(v-model="value.employer" type="text" :disabled="value.situation !== 'working'")
          b-field(grouped group-multiline)
            b-field(:label="t('family_member.average_income')" expanded)
              input.input(v-model="value.average_income" type="text")
            b-field(:label="t('family_member.average_expenses')" expanded)
              input.input(v-model="value.average_expenses" type="text")
            b-field(:label="t('family_member.notes')" expanded)
              input.input(v-model="value.notes" type="text")
          +disabilities_and_diseases(member)
          +support_cards(member)

    .box.box-member.box-other(v-if="value.relationship_to_orphans === 'other'")
      +overlay_delete(member, index)
      button.delete.has-background-danger(@click="remove" type="button")
      .flex.justify-end.mb-4
        span.tag.is-info.is-medium.tag-member
          | {{ t('family_member.relationships.other') }}
      .columns.is-desktop
        .column
          //- other family members
          b-field(grouped group-multiline)
            b-field(:label="t('family_member.relationship_to_orphans')")
              md-autocomplete(v-model="value.relationship_to_orphans_detail" :data="other_relationships" keep-first open-on-focus)
            b-field(:label="t('family_member.first_name')" expanded)
              input.input(v-model="value.first_name" type="text")
            b-field(:label="t('family_member.last_name')" expanded)
              input.input(v-model="value.last_name" type="text")
            b-field(:label="t('family_member.first_name_fr')" expanded)
              input.input(v-model="value.first_name_fr" type="text" dir="ltr")
            b-field(:label="t('family_member.last_name_fr')" expanded)
              input.input(v-model="value.last_name_fr" type="text" dir="ltr")
          b-field(grouped group-multiline)
            b-field(:label="t('family_member.birth_date')")
              b-datepicker(
                v-model="value.birth_date"
                :placeholder="t('date.select_placeholder')"
                icon="calendar-day"
                icon-prev="chevron-right"
                icon-next="chevron-left"
                :editable="true"
              )
            b-field(:label="t('family_member.family_situation')")
              b-select(v-model="value.family_situation" :placeholder="t('family_member.family_situation')")
                option(
                  v-for="label, key in t('family_member.family_situations')"
                  :value="key"
                  :key="key"
                ) {{ label }}
            b-field(:label="t('family_member.phones')")
              input.input.ltr(v-model="value.phones" type="text")
          
          fieldset.bordered.my-4
            legend {{ t('family_member.work') }}
            .columns
              .column.is-narrow(style="margin-top: 32px")
                b-switch(
                  v-model="value.situation"
                  true-value="working" false-value=""
                  dir="ltr"
                )
              .column
                b-field(grouped group-multiline)
                  b-field(:label="t('family_member.job')" expanded)
                    input.input(v-model="value.job" type="text" :disabled="value.situation !== 'working'")
                  b-field(:label="t('family_member.job_level')" expanded)
                    input.input(v-model="value.job_level" type="text" :disabled="value.situation !== 'working'")
                  b-field(:label="t('family_member.employer')" expanded)
                    input.input(v-model="value.employer" type="text" :disabled="value.situation !== 'working'")
          b-field(grouped group-multiline)
            b-field(:label="t('family_member.average_income')" expanded)
              input.input(v-model="value.average_income" type="text")
            b-field(:label="t('family_member.average_expenses')" expanded)
              input.input(v-model="value.average_expenses" type="text")
            b-field(:label="t('family_member.notes')" expanded)
              input.input(v-model="value.notes" type="text")
          +disabilities_and_diseases(member)
          +support_cards(member)

    .box.box-member.box-orphan(v-if="value.relationship_to_orphans === 'orphan'")
      +overlay_delete(member, index)
      button.delete.has-background-danger(@click="remove" type="button")
      .flex.justify-end.mb-4
        span.tag.is-info.is-medium.tag-member
          | {{ t('family_member.relationships.orphan') }}
      .columns.is-desktop
        .column
          //- orphans
          b-field(grouped group-multiline)
            b-field(:label="t('family_member.sex')")
              b-radio(v-model="value.sex" native-value="m") {{ t('family_member.sex_types.m') }}
              b-radio(v-model="value.sex" native-value="f") {{ t('family_member.sex_types.f') }}
            b-field(:label="t('family_member.first_name')" expanded)
              input.input(v-model="value.first_name" type="text")
            b-field(:label="t('family_member.first_name_fr')" expanded)
              input.input(v-model="value.first_name_fr" type="text" dir="ltr")
            b-field(:label="t('family_member.birth_date')")
              b-datepicker(
                v-model="value.birth_date"
                :placeholder="t('date.select_placeholder')"
                icon="calendar-day"
                icon-prev="chevron-right"
                icon-next="chevron-left"
                :editable="true"
              )
            b-field(:label="t('family_member.family_situation')")
              b-select(v-model="value.family_situation" :placeholder="t('family_member.family_situation')")
                option(
                  v-for="label, key in t('family_member.family_situations')"
                  :value="key"
                  :key="key"
                ) {{ label }}
            b-field(:label="t('family_member.situation')")
              b-select(v-model="value.situation" :placeholder="t('family_member.situation')")
                option(
                  v-for="label, key in t('family_member.situations')"
                  :value="key"
                  :key="key"
                ) {{ label }}
            b-field(:label="t('family_member.phones')")
              input.input.ltr(v-model="value.phones" type="text")
          
          fieldset.bordered.my-4(v-if="value.situation === 'working'")
            legend {{ t('family_member.work') }}
            .columns
              .column
                b-field(grouped group-multiline)
                  b-field(:label="t('family_member.job')" expanded)
                    input.input(v-model="value.job" type="text")
                  b-field(:label="t('family_member.job_level')" expanded)
                    input.input(v-model="value.job_level" type="text")
                  b-field(:label="t('family_member.employer')" expanded)
                    input.input(v-model="value.employer" type="text")
          b-field(grouped group-multiline)
            b-field(:label="t('family_member.average_income')" expanded)
              input.input(v-model="value.average_income" type="text")
            b-field(:label="t('family_member.average_expenses')" expanded)
              input.input(v-model="value.average_expenses" type="text")
            b-field(:label="t('family_member.notes')" expanded)
              input.input(v-model="value.notes" type="text")
          +disabilities_and_diseases(member)
          //- +support_cards(member)
</template>

<script>
import MdAutocomplete from './md-autocomplete'

export default {
  name: 'FamilyMemberForm',
  components: {
    MdAutocomplete,
  },
  props: {
    value: { type: Object, required: true },
  },
  data() {
    return {
      other_relationships: this.t('family_member.other_relationships'),
    }
  },
  watch: {
    value() {
      this.$emit('input', this.value)
    },
  },
  methods: {
    remove() {
      if (this.value.id) {
        let changed = { ...this.value, _destroy: true }
        this.$emit('input', changed)
      } else if (confirm(this.t('confirmation_alert'))) {
        this.$emit('remove')
      }
    },
    removeDestroyMark() {
      const changed = { ...this.value, _destroy: false }
      this.$emit('input', changed)
    },
  },
}
</script>

<style scoped>
.field {
  padding-bottom: 0.5rem;
}
.box-member {
  position: relative;
  padding-top: 32px;
  margin-right: 10px;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.tag-member {
  position: absolute;
  top: -16px;
  right: -10px;
}
.support-cards {
  padding-top: 1em;
}

fieldset > legend {
  color: #718096;
}
.overlay-delete {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  border-radius: 6px;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #fda9b7bf;
  backdrop-filter: blur(2px);
  color: #333333c1;
  text-shadow: -3px -1px 3px #ffd9e1;
}
.tag-member {
  z-index: 20;
}
</style>
