<template>
  <div class="data-provider">
    <slot></slot>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  props: {
    url: { type: String, required: true },
    initial: { type: [Object, Array], default: () => [] },
  },
  data() {
    return {
      provided: this.initial,
      error: null,
      loading: false,
      currentPage: 1,
      checkedRows: [],
      checkMode: false,
    }
  },
  computed: {
    checkedIds() {
      return this.checkedRows.map((r) => r.id)
    },
  },
  mounted() {
    this.loading = true
    axios
      .get(this.url)
      .then((res) => (this.provided = res.data))
      .catch((err) => (this.error = err))
      .finally(() => (this.loading = false))
  },
}
</script>
