<template lang="pug">
  .institutions-autocomplete
    slot(:selected="selected")
    b-autocomplete(
      ref="autocomplete"
      v-model="q"
      :data="filtered"
      @select="onSelect"
      v-bind="$attrs"
      field="name"
      )
      template(slot="footer")
        button.button.is-success.is-light(type="button" @click.prevent="isModalActive = true")
          span.icon.is-small: i.fas.fa-plus
          span {{ t('education_institution.new') }}
      template(slot-scope="props")
        .item
          p.is-medium.mb-0
            strong {{ props.option.name }}
            span.mx-1(v-if="props.option.short_name") ( {{ props.option.short_name }} )
          .flex.flex-row
            small.ml-1 {{ t(`education_level.education_stages.${props.option.education_stage}`) }} -
            small {{ props.option.address }}
      template(slot="empty") {{ t('no_search_results') }}
    b-modal(
      v-model="isModalActive"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-modal)
      template(#default="props")
        education-institution-form(@close="props.close" @added="onAdded")
</template>
<script>
import EducationInstitutionForm from './education-institution-form'

export default {
  name: 'InstitutionsAutocomplete',
  components: {
    EducationInstitutionForm,
  },
  props: {
    options: { type: Array, default: null },
    value: { type: Number, default: null },
  },
  data() {
    return {
      opts: this.options,
      q: '', // search query
      selected: null,
      isModalActive: false,
    }
  },
  computed: {
    filtered() {
      return this.opts.filter((i) =>
        [i.name, i.short_name]
          .join('|')
          .toLowerCase()
          .includes(this.q.toLowerCase())
      )
    },
  },
  watch: {
    options(v) {
      this.opts = v
      this.initSelected()
    },
  },
  mounted() {
    this.initSelected()
  },
  methods: {
    onSelect(option) {
      this.selected = option
      this.$emit('select', option)
    },
    onAdded(value) {
      this.opts.push(value)
      this.$refs.autocomplete.setSelected(value)
    },
    initSelected() {
      if (this.value) {
        const s = this.opts.find((i) => i.id === this.value)
        if (s) this.$refs.autocomplete.setSelected(s)
      }
    },
  },
}
</script>
