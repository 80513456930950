<template lang="pug">
  div#md-family-members-form
    //- code(dir="ltr"): pre {{ JSON.stringify(members, null, 2) }}
    
    transition-group(name="list" tag="div")
      div(v-for="member, index in members" :key="member.id || member.vid")
        family-member-form(v-model="members[index]" @remove="removeMemberAt(index)")
    .actions
      button.button.is-primary.mx-2(type="button" @click="addMember('orphan')") {{t('family_member.add_member_orphan')}}
      button.button.is-primary.mx-2(type="button" @click="addMember('other')") {{t('family_member.add_member_other')}}
      button.button.is-primary.mx-2(type="button" @click="save()") {{t('save')}}
</template>

<script>
import axios from 'axios'
import sortBy from 'lodash-es/sortBy'
import cloneDeepWith from 'lodash-es/cloneDeepWith'
import { dateReplacer, validationErrorToSnack } from '../util'
import FamilyMemberForm from './family-member-form'

let sorted_relationships = ['father', 'mother', 'other', 'orphan']
let default_members = [
  { relationship_to_orphans: 'father', vid: 0 },
  { relationship_to_orphans: 'mother', vid: 1 },
  { relationship_to_orphans: 'orphan', vid: 2 },
]

// complete member's support_cards list with other types of support cards he doesn't have for
function complete_other_support_cards(support_cards, support_card_types) {
  if (!support_cards) support_cards = []

  Object.entries(support_card_types).forEach(([key]) => {
    let found = support_cards.find((card) => card.key === key)
    if (found) found.active = true
    else support_cards.push({ key })
  })
  return sortBy(support_cards, 'key')
}

function initialize_members_data(members_attributes, { support_card_types }) {
  if (!members_attributes.length) {
    members_attributes = default_members
  } else {
    let father = members_attributes.find(
      (m) => m.relationship_to_orphans === 'father'
    )
    let mother = members_attributes.find(
      (m) => m.relationship_to_orphans === 'mother'
    )
    if (!father)
      members_attributes.push({ relationship_to_orphans: 'father', vid: 0 })
    if (!mother)
      members_attributes.push({ relationship_to_orphans: 'mother', vid: 0 })
  }

  return sortBy(members_attributes, [
    (m) => sorted_relationships.indexOf(m.relationship_to_orphans),
    'id',
  ]).map((m) => {
    m.support_cards = complete_other_support_cards(
      m.support_cards,
      support_card_types
    )
    if (m.chronic_diseases) m.chronic_diseases = m.chronic_diseases.split(',')
    if (m.birth_date) m.birth_date = new Date(m.birth_date)
    if (m.death_date) m.death_date = new Date(m.death_date)
    return m
  })
}

export default {
  components: {
    FamilyMemberForm,
  },
  props: {
    membersAttributes: { type: Array, default: () => [] },
  },
  data: function() {
    return {
      support_card_types: this.t('family_member.support_cards.types'),
      members: [],
    }
  },
  mounted() {
    this.initializeMembers(this.membersAttributes)
  },
  methods: {
    initializeMembers(data) {
      this.members = initialize_members_data(data, {
        support_card_types: this.support_card_types,
      })
    },
    addMember(relationship_to_orphans) {
      this.members.push({
        vid: new Date().getTime(),
        relationship_to_orphans,
        support_cards: complete_other_support_cards(
          [],
          this.support_card_types
        ),
      })
    },
    removeMemberAt(index) {
      this.members.splice(index, 1)
    },
    isMemberEmpty(member) {
      // empty if only the prefilled fields are set
      return !Object.keys(member).filter(
        (key) =>
          !['vid', 'relationship_to_orphans', 'support_cards'].includes(key)
      ).length
    },
    isMemberOther(member) {
      return !['father', 'mother', 'orphan'].includes(
        member.relationship_to_orphans
      )
    },
    async save() {
      // transform data for saving on server
      // filter out the empty members
      this.members = this.members.filter((m) => !this.isMemberEmpty(m))
      let data = cloneDeepWith(this.members, dateReplacer)
      data.forEach((m) => {
        if (m.chronic_diseases && m.chronic_diseases.length) {
          m.chronic_diseases = m.chronic_diseases.sort().join(',')
        }
        m.support_cards_attributes = m.support_cards
          .filter((card) => card.id || card.active)
          .map((card) => {
            if (card.id && !card.active) card._destroy = true
            return card
          })
        delete m.support_cards
      })
      console.log('to be saved', data)
      let loadingComponent = this.$buefy.loading.open({ canCancel: false })
      try {
        let res = await axios.patch(window.location, {
          family: { family_members_attributes: data },
        })
        this.initializeMembers(res.data)
        loadingComponent.close()
        this.$buefy.snackbar.open(this.t('update_success'))
      } catch (error) {
        loadingComponent.close()
        console.error(error.response)
        this.$buefy.snackbar.open(validationErrorToSnack(error.response.data))
      }
    },
  },
}
</script>

<style scoped>
/* list transition animation */
.list-enter-active,
.list-leave-active,
.list-move {
  transition: all 300ms ease-in-out;
}
.list-enter {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}
.list-enter-to {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}
.list-leave-to {
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
}
</style>
