// parse current url for ids
const current_family_member_id = () => {
  const r = window.location.href.match(/\/family_members\/(\d+)/)
  if (r && r.length) return r[1]
}
const current_education_level_id = () => {
  const r = window.location.href.match(/\/education_levels\/(\d+)/)
  if (r && r.length) return r[1]
}
// family members
const family_members = () => '/family_members'
const family_member = (family_member_id) =>
  `/family_members/${family_member_id}`
const edit_family_member = (family_member_id) =>
  family_member(family_member_id) + '/edit'

const current_family_member = () => family_member(current_family_member_id())
const edit_current_family_member = () =>
  edit_family_member(current_family_member_id())

// education levels
const family_member_education_levels = (family_member_id) =>
  `/family_members/${family_member_id}/education_levels`
const education_level = (family_member_id, education_level_id) =>
  `/family_members/${family_member_id}/education_levels/${education_level_id}`
const edit_education_level = (family_member_id, education_level_id) =>
  education_level(family_member_id, education_level_id) + '/edit'

const current_family_member_education_levels = () =>
  family_member_education_levels(current_family_member_id())
const current_education_level = () =>
  education_level(current_family_member_id(), current_education_level_id())
const edit_current_education_level = () => current_education_level() + '/edit'

// education institutions
const education_institutions = () => '/education_institutions'
const education_branches = () => '/education_levels/branches'

export default {
  family_members,
  family_member,
  edit_family_member,
  family_member_education_levels,
  education_level,
  edit_education_level,
  current_family_member_id,
  current_education_level_id,
  current_family_member,
  edit_current_family_member,
  current_family_member_education_levels,
  current_education_level,
  edit_current_education_level,
  education_institutions,
  education_branches,
}
