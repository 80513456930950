<template lang="pug">
  b-autocomplete(
    v-model="q"
    :data="filtered"
    v-bind="$attrs")
</template>

<script>
export default {
  name: 'MdAutocomplete',
  props: {
    value: { type: [String, Number], default: null },
    data: { type: Array, required: true },
  },
  data: function() {
    return {
      q: this.value || '',
    }
  },
  computed: {
    filtered() {
      return this.data.filter(
        (option) =>
          option
            .toString()
            .toLowerCase()
            .indexOf(this.q.toLowerCase()) >= 0
      )
    },
  },
  watch: {
    q(v) {
      this.$emit('input', v)
    },
  },
}
</script>

<style scoped></style>
