<template lang="pug">
  div
    // code.ltr: pre {{ institution }}
    // code.ltr: pre {{ value }}
    
    form(@submit.prevent="onSubmit" v-if="editable")
      .columns
        .column
          b-field(grouped group-multiline)
            b-field(:label="t('education_level.year')")
              b-select(required v-model="value.year" :placeholder="t('education_level.year')")
                option(
                  v-for="[key, label] in years"
                  :value="key"
                  :key="key"
                ) {{ label }}
            b-field(:label="t('education_level.education_stage')")
              b-select(required v-model="value.education_stage" :placeholder="t('education_level.education_stage')")
                option(
                  v-for="label, key in education_stages"
                  :value="key"
                  :key="key"
                ) {{ label }}
          b-field(grouped group-multiline)
            b-field(:label="t('education_level.education_institution_id')" expanded)
              institution-autocomplete(
                :options="education_institutions"
                :value="value.education_institution_id"
                @select="onSelectEducationInstitution"
                :placeholder="t('education_level.education_institution_id')"
                open-on-focus
              )
            b-field(:label="t('education_level.branch')" expanded)
              md-autocomplete(v-model="value.branch" :data="education_branches" keep-first open-on-focus)

            b-field(:label="t('education_level.level')" expanded)
              md-autocomplete(v-model="value.level" :data="t('education_level.level_options')" keep-first open-on-focus)
          b-field(grouped group-multiline)
            b-field(:label="t('education_level.first_session_score')" expanded)
              input.input(v-model="value.first_session_score" type="number" step=".01" min="0" pattern="^\d*(\.\d{0,2})?$")
            b-field(:label="t('education_level.second_session_score')" expanded)
              input.input(v-model="value.second_session_score" type="number" step=".01" min="0" pattern="^\d*(\.\d{0,2})?$")
            b-field(:label="t('education_level.annual_score')" expanded)
              input.input(v-model="value.annual_score" type="number" step=".01" min="0" pattern="^\d*(\.\d{0,2})?$")
            b-field(:label="t('education_level.passed')" expanded)
              b-radio-button(v-model="value.passed" :native-value="true" type="is-success")
                span {{ t('education_level.passed_options.true') }}
              b-radio-button(v-model="value.passed" :native-value="false" type="is-danger")
                span {{ t('education_level.passed_options.false') }}
              .flex(style="align-items: center; width: 2em; justify-content: center;")
                button.delete(@click.prevent="value.passed = undefined")
          b-field(grouped group-multiline)
            b-field(:label="t('education_level.notes')" expanded)
              textarea.textarea(v-model="value.notes")
      .my-4
        input.button.is-primary(type="submit" :value="t('save')")
        button.button.is-danger.mx-4(@click.prevent="destroy" v-if="value.id") {{ t('destroy') }}
      //- code.ltr: pre {{ value }}
    
    .section.show-section.p-2(v-else)
      .flex.justify-space-between
        h4.subtitle.is-4 {{ t('education_level.year') }} {{ value.year_label }}
        a.button.is-info.is-light.mx-2(:href="getLevelEditUrl()")
          span.icon.is-small: i.far.fa-edit
          span {{ t('edit') }}
      b-field(grouped)
        b-field.text-center(:label="t('education_level.education_stage')" expanded)
          p.text-center {{ education_stages[value.education_stage] || '-' }}
        b-field.text-center(:label="t('education_level.branch')" expanded)
          p.text-center {{ value.branch || '-' }}
        b-field.text-center(:label="t('education_level.level')" expanded)
          p.text-center {{ value.level || '-' }}
      b-field(grouped v-if="value.education_institution")
        b-field.text-center(:label="t('helpers.label.education_institution.is_private')" expanded)
          span.tag.is-medium.education-institution {{ t('education_institution.is_private.' + value.education_institution.is_private) || '-' }}
        b-field.text-center(:label="t('helpers.label.education_institution.name')" expanded)
          p.text-center {{ value.education_institution.name || '-' }}
        b-field.text-center(:label="t('helpers.label.education_institution.phones')" expanded)
          p.text-center {{ value.education_institution.phones || '-' }}
        b-field.text-center(:label="t('helpers.label.education_institution.address')" expanded)
          p.text-center {{ value.education_institution.address || '-' }}
      b-field(grouped v-else)
        b-field.text-center(:label="t('helpers.label.education_institution.name')" expanded)
          span.tag.is-medium.education-institution {{ t('blanke') }}
      
      b-field(grouped)
        b-field.text-center(:label="t('education_level.first_session_score')" expanded)
          p.text-center {{ value.first_session_score || '-' }}
        b-field.text-center(:label="t('education_level.second_session_score')" expanded)
          p.text-center {{ value.second_session_score || '-' }}
        b-field.text-center(:label="t('education_level.annual_score')" expanded)
          p.text-center {{ value.annual_score || '-' }}
        b-field.text-center(:label="t('education_level.passed')" expanded)
          p.text-center.tag.is-medium(:class="{'is-success' : value.passed, 'is-danger': value.passed === false}") {{ t('education_level.passed_options.' + value.passed) || '-'  }}
      b-field(grouped v-if="value.notes")
        b-field.text-center(:label="t('education_level.notes')" expanded)
          p.pre-line {{ value.notes }}
</template>
<script>
import axios from 'axios'
import UrlUtil from '../UrlUtil'
import { successToastParams, validationErrorToSnack, wait } from '../util'
import InstitutionAutocomplete from './institutions-autocomplete.vue'
import MdAutocomplete from './md-autocomplete.vue'

const current_year = new Date().getFullYear()
const numberOfYears = 10
const years = Array.from(
  { length: numberOfYears },
  (_, i) => current_year - i
).map((x) => [x, `${x + 1}\\${x}`])
export default {
  components: {
    InstitutionAutocomplete,
    MdAutocomplete,
  },
  props: {
    value: { type: Object, required: true },
    editable: { type: Boolean, default: false },
  },
  data() {
    return {
      education_stages: this.t('education_level.education_stages'),
      years,
      education_institutions: [],
      education_branches: [],
    }
  },
  computed: {
    filteredInstitutions() {
      return this.education_institutions.filter(
        (i) =>
          i.name.toLowerCase().indexOf(this.institutionSearch.toLowerCase()) >=
          0
      )
    },
  },
  async created() {
    console.log(this.value)
    if (!this.editable) return
    this.fetchEducationInstitutions()
    this.fetchEducationBranches()
  },
  methods: {
    getLevelEditUrl() {
      return UrlUtil.edit_education_level(
        UrlUtil.current_family_member_id(),
        this.value.id
      )
    },
    async fetchEducationInstitutions() {
      const { data } = await axios.get(UrlUtil.education_institutions())
      this.education_institutions = data
    },
    async fetchEducationBranches() {
      const { data } = await axios.get(UrlUtil.education_branches())
      this.education_branches = data
    },
    onSelectEducationInstitution(option) {
      console.log('---------select--------', option)
      // TODO fix this
      // eslint-disable-next-line vue/no-mutating-props
      this.value.education_institution_id = option && option.id
    },
    async destroy() {
      if (!confirm(this.t('confirmation_alert'))) return
      const url = UrlUtil.current_education_level()
      let loadingComponent = this.$buefy.loading.open({ canCancel: false })
      try {
        await axios.delete(url)
        loadingComponent.close()
        window.Turbolinks.visit(UrlUtil.current_family_member(), {
          action: 'replace',
        })
      } catch (error) {
        loadingComponent.close()
        console.error(error)
        this.$buefy.snackbar.open(validationErrorToSnack(error.response.data))
      }
    },
    async onSubmit() {
      let loadingComponent = this.$buefy.loading.open({ canCancel: false })
      try {
        const updateOrCreateRequest = this.value.id
          ? axios.patch(UrlUtil.current_education_level(), {
              education_level: this.value,
            })
          : axios.post(UrlUtil.current_family_member_education_levels(), {
              education_level: this.value,
            })
        await updateOrCreateRequest
        loadingComponent.close()
        this.$buefy.toast.open(successToastParams)
        await wait(500)
        window.Turbolinks.visit(window.location.toString(), {
          action: 'replace',
        })
      } catch (error) {
        loadingComponent.close()
        console.error(error)
        this.$buefy.snackbar.open(validationErrorToSnack(error.response.data))
      }
    },
  },
}
</script>
<style lang="scss" scoped>
input[type='number'] {
  direction: ltr;
}
.columns {
  margin-bottom: 0;
}
</style>
