import Vue from 'vue/dist/vue.esm'
import { Mixin as VueTurbolinksMixin } from 'vue-turbolinks'
import Buefy from 'buefy'
import App from './app.vue'
import DatePicker from './date-picker.vue'
import MdAutocomplete from './md-autocomplete.vue'
import MdObjectAutocomplete from './md-object-autocomplete.vue'
import MdTagsAutocomplete from './md-tags-autocomplete.vue'
import MdFileUpload from './md-file-upload.vue'
import DataProvider from './data-provider.vue'
import FamilyMembersForm from './family-members-form.vue'
import FamilyMemberForm from './family-member-form.vue'
import FamilyMemberEducationLevel from './family-member-education-level.vue'
import FamilyMemberEducation from './family-member-education.vue'
import InstitutionsAutocomplete from './institutions-autocomplete.vue'
import FamilyDecisionForm from './family-decision-form.vue'
import format from 'date-fns/format'
import get from 'lodash-es/get'
import curry from 'lodash-es/curry'
import axios from 'axios'

// set default content-type header
axios.defaults.headers.common['Content-Type'] = 'application/json'
axios.defaults.headers.common['Accept'] = 'application/json'

import { ar as translations } from '../../../config/locales/ar.yml'
// console.log('locale', translations)

Vue.prototype.translations = translations
Vue.prototype.t = curry(get, 2)(translations)
Vue.prototype.formatDate = (date) => format(new Date(date), 'yyyy-MM-dd')
Vue.prototype.formatDatetime = (date) => format(new Date(date), 'yyyy-MM-dd p')
Vue.prototype.confirm = window.confirm

Vue.use(Buefy, {
  defaultIconPack: 'fas',
  defaultDateFormatter: (date) => format(date, 'yyyy-MM-dd'),
  defaultIconPrev: 'chevron-right',
  defaultIconNext: 'chevron-left',
  defaultDayNames: ['ح', 'ن', 'ث', 'ر', 'خ', 'ج', 'س'],
  defaultMonthNames: [
    'يناير',
    'فبراير',
    'مارس',
    'أبريل',
    'ماي',
    'يونيو',
    'يوليوز',
    'غشت',
    'شتنبر',
    'أكتوبر',
    'نونبر',
    'دجنبر',
  ],
  defaultFirstDayOfWeek: 1,
})

document.addEventListener('turbolinks:load', () => {
  axios.defaults.headers.common['X-CSRF-Token'] = document
    .querySelector('meta[name="csrf-token"]')
    .getAttribute('content')

  new Vue({
    el: '[data-behavior="vue"]',
    components: {
      DatePicker,
      MdAutocomplete,
      MdObjectAutocomplete,
      MdTagsAutocomplete,
      MdFileUpload,
      DataProvider,
      FamilyMembersForm,
      FamilyMemberForm,
      FamilyMemberEducationLevel,
      FamilyMemberEducation,
      InstitutionsAutocomplete,
      FamilyDecisionForm,
      App,
    },
    mixins: [VueTurbolinksMixin],
    data: () => {
      return {
        message: 'Can you say hello?',
        loaded: false,
        one: 1,
        x: null,
      }
    },
    mounted() {
      this.loaded = true
    },
  })
})

Vue.component('Apexchart', async () => {
  const VueApexCharts = await import('vue-apexcharts')
  Vue.use(VueApexCharts)
  return VueApexCharts
})
