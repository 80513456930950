<script>
import DatePicker from './date-picker'
import MdAutocomplete from './md-autocomplete.vue'
import MdObjectAutocomplete from './md-object-autocomplete.vue'

export default {
  name: 'FamilyDecisionForm',
  components: {
    DatePicker,
    MdAutocomplete,
    MdObjectAutocomplete,
  },
  props: {
    initial: { type: Object, default: () => ({}) },
  },
  data() {
    return {
      situation: this.initial?.situation,
    }
  },
  methods: {
    family_changed($event) {
      const family_id = $event.target.value
      window.location.search = `family_id=${family_id}`
    },
  },
}
</script>
