<template lang="pug">
  form(action="" @submit.prevent="onSubmit")
    .modal-card.institution-form
      header.modal-card-head
        p.modal-card-title {{ t('education_institution.new') }}
        button.delete(type="button" @click="$emit('close')")
      section.modal-card-body
        b-field(horizontal=true :label="t('helpers.label.education_institution.name')")
          input.input(v-model="value.name" required dir="auto" type="text")
        b-field(horizontal=true :label="t('helpers.label.education_institution.short_name')")
          input.input(v-model="value.short_name" dir="ltr" type="text" :placeholder="t('education_institution.short_name_placeholder')")
        b-field(horizontal=true :label="t('helpers.label.education_institution.education_stage')")
          b-select(required v-model="value.education_stage" :placeholder="t('education_level.education_stage')")
              option(
                v-for="label, key in t('education_level.education_stages')"
                :value="key"
                :key="key"
              ) {{ label }}
        b-field(horizontal=true :label="t('helpers.label.education_institution.phones')")
          textarea.textarea(v-model="value.phones" dir="auto" rows="3")
        b-field(horizontal=true :label="t('helpers.label.education_institution.address')")
          input.input(v-model="value.address" dir="auto" type="text")
        b-field(horizontal=true :label="t('helpers.label.education_institution.is_private')")
          .control
            b-radio(v-model="value.is_private" name="is_private" :native-value="false")
              | {{ t('education_institution.is_private.false') }}
            b-radio(v-model="value.is_private" name="is_private" :native-value="true")
              | {{ t('education_institution.is_private.true') }}
      footer.modal-card-foot
        button.button.mx-2( @click="$emit('close')" type="button") {{ t('cancel') }}
        button.button.is-primary.mx-2 {{ t('save') }}
</template>

<script>
import axios from 'axios'
import UrlUtil from '../UrlUtil'
import { validationErrorToSnack } from '../util'
export default {
  data() {
    return {
      value: {},
    }
  },
  methods: {
    async onSubmit() {
      const url = UrlUtil.education_institutions()
      const loadingComponent = this.$buefy.loading.open({ canCancel: false })
      try {
        const { data } = await axios.post(url, this.value)
        this.$emit('input', {})
        this.$emit('added', data)
        this.$emit('close')
        this.$buefy.snackbar.open(this.t('update_success'))
      } catch (error) {
        console.error(error)
        this.$buefy.snackbar.open(validationErrorToSnack(error.response.data))
      } finally {
        loadingComponent.close()
      }
    },
  },
}
</script>
