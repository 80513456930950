<template>
  <div class="md-tags-autocomplete">
    <b-taginput
      v-model="selected"
      autocomplete
      :data="filteredData"
      :allow-new="false"
      :open-on-focus="true"
      :field="field"
      icon="fas fa-tag"
      :placeholder="t('add_tag')"
      @typing="getFilteredData"
    >
    </b-taginput>
    <input type="hidden" :name="name" :value="value" />
  </div>
</template>

<script>
export default {
  props: {
    field: { type: String, default: 'name' },
    data: { type: Array, required: true },
    name: { type: String, default: null },
    initial: { type: Array, default: null },
  },
  data() {
    return {
      selected: [],
      filteredData: this.data,
    }
  },
  computed: {
    value() {
      return JSON.stringify(this.selected.map((i) => i.id))
    },
  },
  mounted() {
    this.selected = this.data.filter((x) => this.initial.includes(x.id))
  },
  methods: {
    getFilteredData(text) {
      const q = text.toLowerCase()
      this.filteredData = this.data.filter((option) => {
        return option[this.field]
          ?.toString()
          ?.toLowerCase()
          ?.includes(q)
      })
    },
  },
}
</script>

<style></style>
